@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CUSTOM STYLES */

.themed-input {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-800 outline-themeColorPrimary focus:outline focus:outline-[3px]
}

.themed-input-edit {
  @apply shadow appearance-none border rounded w-full min-w-[300px] py-2 px-3 text-gray-800 outline-themeColorPrimary focus:outline focus:outline-[3px]
}

/* src/styles/loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: spin 1s ease infinite;
}

.button-loader {
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin 1s
   ease infinite;
}

.modal-animation {
  animation: modalAnimation 350ms;
}


@keyframes modalAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bottom-top {
  0% {
    transform: translateY(1000px);
  }
  100% {
    transform: translateY(0)
  }
}

